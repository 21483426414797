import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { VolunteerInfo } from '../components/VolunteerInfo'
import { getVolunteerById } from '../api'
import { VolunteerDetails } from '../types'
import { Loader } from '../components/Loader'
import { Page } from '../components/Page'
import { Alert } from '@mui/material'

export function Volunteer() {
  const { id } = useParams()
  const { state } = useLocation()
  const [data, setData] = useState<VolunteerDetails>()

  useEffect(() => {
    getVolunteerById(id!).then(data => setData(data))
  }, [id])

  if (data)
    return (
      <Page size="lg">
        {data.black_list && (
          <Alert severity="error" sx={{ mb: 2 }}>
            В чорному списку
          </Alert>
        )}
        {data.is_deleted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Архівовано
          </Alert>
        )}
        <VolunteerInfo data={data} organization={state.org} />
      </Page>
    )

  return <Loader />
}
