export interface TableDataResponse {
  count: number
  next: number | null
  previous: number | null
}

export interface OrganizationsResponse extends TableDataResponse {
  results: Array<{
    name: string
    serial_number: string
    edrpou: string
    head_phone_number: string
    full_name: string
    id: number
  }>
}

export type Url = { site_name: string; url: string }

export interface OrganizationAddress {
  flat: string
  house: string
  region: string
  street: string
  district: string
  settlement: string
}

export interface OrganizationDetailResponse {
  address: OrganizationAddress
  id: number
  urls: Url[]
  name: string
  serial_number: string
  edrpou: string
  type: null
  details: string
  head_phone_number: string
  activity: Array<{ name: string } | null>
  description: string
  logo: string
  is_deleted: boolean
  black_list: boolean
  head_full_name: string
}

export interface Volunteer {
  first_name: string
  last_name: string
  middle_name: string
  phone_number: string
  serial_number: string
  is_deleted: boolean
  black_list: boolean
  id: number
  organization: {
    id: number
    name: string
  }
}

export interface VolunteerDetails extends Omit<Volunteer, 'organization'> {
  organization: number
  birth_date: string
  position: string
  certificate_date: string
  profile_image: string
  achievements: string
  achievement_documents: string
  registration_address: string
  living_address: string
}

export interface VolunteersResponse extends TableDataResponse {
  results: Volunteer[]
}

export interface OrgVolunteersResponse extends TableDataResponse {
  results: Omit<Volunteer, 'organization'>[]
}

export interface TableRowType {
  title: string
  name: string
}

export interface TableRequestParams {
  search?: string
  limit: number
  offset: number
}

export interface OrgVolunteersParams
  extends Omit<TableRequestParams, 'search'> {
  org_id: number | string
}

// App state

export interface CurrentUser {
  username: string
  first_name: string
  last_name: string
}

export type User = CurrentUser | null

export type TableSettings = {
  page: number
  rowsPerPage: number
  search: string
}

export enum Action {
  user = 'user',
  org = 'organizations',
  volunteers = 'volunteers',
}

export interface ReducerActionType {
  type: Action
  payload: User | TableSettings
}

export interface AppInitialState {
  user: User
  organizations: TableSettings
  volunteers: TableSettings
}

export interface AppContextState extends AppInitialState {
  dispatch: (data: ReducerActionType) => void
}
