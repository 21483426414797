import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react'
import { Table, TableHeader } from '../components/Table'
import { Page } from '../components/Page'
import { VOLUNTEERS_TABLE_COLUMNS } from '../constants'
import { debounce } from 'lodash'
import { getVolunteers } from '../api'
import { Action, VolunteersResponse } from '../types'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../utils'
import routes from '../routes'

export function Volunteers() {
  const navigate = useNavigate()
  const { volunteers, dispatch } = useContext(AppContext)
  const [page, setPage] = useState(volunteers.page)
  const [rowsPerPage, setRowsPerPage] = useState(volunteers.rowsPerPage)
  const [search, setSearch] = useState(volunteers.search)
  const [data, setData] = useState<VolunteersResponse>()

  useEffect(() => {
    getVolunteers({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search,
    }).then(data => {
      setData(data)
      dispatch({
        type: Action.volunteers,
        payload: { page, rowsPerPage, search },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])

  const handleRowClick = (id: number) =>
    navigate(routes.volunteer(id), {
      state: {
        org: data?.results.find(item => item.id === id)?.organization.name,
      },
    })

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
      debounce(() => {
        getVolunteers({
          offset: 0,
          limit: rowsPerPage,
          search: value,
        }).then(data => {
          setPage(0)
          setData(data)
          dispatch({
            type: Action.volunteers,
            payload: { page: 0, rowsPerPage, search: value },
          })
        })
      }, 400)()
    },
    [rowsPerPage, dispatch]
  )

  const volunteerList = useMemo(() => {
    return data?.results.map(item => ({
      ...item,
      organization: item.organization?.name || 'Без організації',
    }))
  }, [data?.results])

  return (
    <Page>
      <TableHeader
        title="Волонтери"
        onSearchChange={handleSearch}
        searchDefaultValue={search}
      />
      {volunteerList && data && (
        <Table
          currentPage={page}
          onChangePage={setPage}
          columns={VOLUNTEERS_TABLE_COLUMNS}
          rows={volunteerList}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={data.count}
          onRowClick={handleRowClick}
        />
      )}
    </Page>
  )
}
