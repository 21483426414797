import React, { ChangeEvent } from 'react'
import Paper from '@mui/material/Paper'
import MaterialTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import { TABLE_ROWS_PER_PAGE } from '../../constants'
import { StyledEngineProvider } from '@mui/material/styles'
import { ArchivedBadgeCell, BlackListBadgeCell } from './BadgeCell'
import { TableRowType } from '../../types'
import styles from './Table.module.scss'

interface TableProps {
  columns: TableRowType[]
  rows: Record<string, string | number | boolean>[]
  currentPage: number
  onChangePage: (page: number) => void
  rowsPerPage: number
  setRowsPerPage: (page: number) => void
  count: number
  onRowClick?: (id: number) => void
}

export function Table(props: TableProps) {
  const {
    columns,
    rows,
    currentPage,
    onChangePage,
    rowsPerPage,
    setRowsPerPage,
    count,
    onRowClick,
  } = props

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    onChangePage(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className={styles.container}>
        <MaterialTable stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(item => (
                <StyledEngineProvider injectFirst key={item.title}>
                  <TableCell className={styles.table_header}>
                    {item.title}
                  </TableCell>
                </StyledEngineProvider>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow
                hover
                tabIndex={-1}
                key={+row.serial_number}
                sx={{ cursor: 'pointer' }}
                onClick={() => onRowClick?.(row.id as number)}
              >
                {columns.map(({ name }, i) => {
                  const isDeleted = columns.length - 1 === i && row.is_deleted
                  const blackList = 0 === i && row.black_list
                  return (
                    <TableCell key={row[name] as string}>
                      {isDeleted ? (
                        <ArchivedBadgeCell text={String(row[name])} />
                      ) : blackList ? (
                        <BlackListBadgeCell text={String(row[name])} />
                      ) : (
                        row[name]
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <TablePagination
        component="div"
        count={count}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Рядків на сторінці: "
      />
    </Paper>
  )
}
