import React, { useEffect, useReducer, useState } from 'react'
import { Router } from './Router'
import { ThemeProvider } from '@mui/material'
import { Loader } from './components/Loader'
import { Action } from './types'
import { getCurrentUser } from './api'
import { AppContext, appInitialState, appReducer, theme } from './utils'

function App() {
  const [state, dispatch] = useReducer(appReducer, appInitialState)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCurrentUser()
      .then(userData => {
        dispatch({ type: Action.user, payload: userData })
      })
      .finally(() => setLoading(false))
  }, [])

  if (loading) return <Loader />

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider theme={theme}>
        <Router user={state.user} />
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App
