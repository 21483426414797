import api from './api'
import {
  OrganizationsResponse,
  OrganizationDetailResponse,
  TableRequestParams,
} from '../types'

export async function getOrganizations(
  params: TableRequestParams
): Promise<OrganizationsResponse> {
  const resp = await api.get<OrganizationsResponse>('organizations', { params })
  return resp.data
}

export async function getOrganizationById(
  id: number
): Promise<OrganizationDetailResponse> {
  const resp = await api.get<OrganizationDetailResponse>(`organizations/${id}`)
  return resp.data
}
