import api from './api'
import { CurrentUser } from '../types'

interface LoginResponse {
  key: string
  user: CurrentUser
}

interface LoginPayload {
  username: string
  password: string
}

export async function login(payload: LoginPayload): Promise<LoginResponse> {
  const resp = await api.post<LoginResponse>('user/login', payload)
  return resp.data
}

export async function logout() {
  await api.get('user/logout')
}

export async function getCurrentUser(): Promise<CurrentUser> {
  const resp = await api.get<CurrentUser>('user/current')
  return resp.data
}
