import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import {
  Organizations,
  Volunteers,
  Login,
  Organization,
  Volunteer,
} from './pages'
import routes from './routes'
import { User } from './types'

interface RouterProps {
  user: User
}

export function Router({ user }: RouterProps) {
  if (user)
    return (
      <Routes>
        <Route path={routes.organizations} element={<Organizations />} />
        <Route path={routes.volunteers} element={<Volunteers />} />
        <Route path={routes.organization(':id')} element={<Organization />} />
        <Route path={routes.volunteer(':id')} element={<Volunteer />} />
        <Route path="*" element={<Navigate to={routes.organizations} />} />
      </Routes>
    )

  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path="*" element={<Navigate to={routes.login} />} />
    </Routes>
  )
}
