import api from './api'
import { omit } from 'lodash'

import {
  VolunteersResponse,
  TableRequestParams,
  OrgVolunteersParams,
  OrgVolunteersResponse,
  VolunteerDetails,
} from '../types'

export async function getVolunteers(
  params: TableRequestParams
): Promise<VolunteersResponse> {
  const resp = await api.get<VolunteersResponse>('volunteer', { params })
  return resp.data
}

export async function getOrgVolunteers(
  params: OrgVolunteersParams
): Promise<OrgVolunteersResponse> {
  const resp = await api.get<OrgVolunteersResponse>(
    `volunteer/organization/${params.org_id}`,
    { params: omit(params, 'org_id') }
  )
  return resp.data
}

export async function getVolunteerById(
  id: number | string
): Promise<VolunteerDetails> {
  const resp = await api.get<VolunteerDetails>(`volunteer/${id}`)
  return resp.data
}
