import React, { useState } from 'react'
import { VolunteerDetails } from '../../types'
import { List, Stack } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { DataListItem } from '../DataListItem'
import styles from './VolunteerInfo.module.scss'

interface VolunteerInfoProps {
  data: VolunteerDetails
  organization: string
}
export function VolunteerInfo({ data, organization }: VolunteerInfoProps) {
  const [profileImageError, setProfileImageError] = useState<boolean>(
    data.profile_image ? false : true
  )
  const [documentsImageError, setDocumentsImageError] = useState<boolean>(
    data.achievement_documents ? false : true
  )
  const calculateDaysDifference = () => {
    const today = new Date()
    const certificateDate = new Date(data.certificate_date)

    if (!data.certificate_date) {
      return 'Invalid certificate date'
    }
    const timeDifference = today.getTime() - certificateDate.getTime()
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    return daysDifference
  }

  const daysSinceCertification = calculateDaysDifference()

  return (
    <Stack direction="row">
      <div>
        {profileImageError && <PersonIcon className={styles.image_plug} />}
        {!profileImageError && (
          <img
            className={styles.image}
            onError={() => setProfileImageError(true)}
            src={data.profile_image}
            alt="фото"
          />
        )}
      </div>
      <List sx={{ p: 0, flexGrow: 1 }}>
        <DataListItem
          name="ПІБ"
          value={`${data.last_name} ${data.first_name} ${data.middle_name}`}
        />
        <DataListItem name="День народження" value={data.birth_date} />
        <DataListItem
          name="Адреса реєстрації"
          value={data.registration_address}
        />
        <DataListItem name="Адреса проживання" value={data.living_address} />
        <DataListItem name="Телефон" value={data.phone_number} />
        <DataListItem name="Організація" value={organization} />
        <DataListItem name="Посада" value={data.position} />
        <DataListItem name="Серійний номер" value={data.serial_number} />
        <DataListItem
          name="Дата видачі посвідчення"
          value={data.certificate_date}
        />
        {daysSinceCertification !== 'Invalid certificate date' && (
          <DataListItem
            name="Строк роботи, днів"
            value={daysSinceCertification}
          />
        )}
        <DataListItem name="Здобутки" value={data.achievements} />
      </List>
      <div>
        {!documentsImageError && (
          <img
            className={styles.image}
            onError={() => setDocumentsImageError(true)}
            src={data.achievement_documents}
            alt="документи"
          />
        )}
      </div>
    </Stack>
  )
}
