import { createContext } from 'react'
import { TABLE_ROWS_PER_PAGE } from '../constants'
import { AppContextState } from '../types'

export const tableSettingsDefault = {
  page: 0,
  rowsPerPage: TABLE_ROWS_PER_PAGE[1],
  search: '',
}

const tableDefaultState = {
  ...tableSettingsDefault,
  setTableSettings() {},
}

export const AppContext = createContext<AppContextState>({
  user: null,
  dispatch() {},
  organizations: tableDefaultState,
  volunteers: tableDefaultState,
})
