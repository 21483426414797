import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, TableHeader } from '../components/Table'
import { Page } from '../components/Page'
import { ORG_TABLE_COLUMNS } from '../constants'
import { debounce } from 'lodash'
import { Action, OrganizationsResponse } from '../types'
import { getOrganizations } from '../api'
import routes from '../routes'
import { AppContext } from '../utils'

export function Organizations() {
  const { organizations, dispatch } = useContext(AppContext)
  const [page, setPage] = useState(organizations.page)
  const [rowsPerPage, setRowsPerPage] = useState(organizations.rowsPerPage)
  const [search, setSearch] = useState(organizations.search)
  const [data, setData] = useState<OrganizationsResponse>()
  const navigate = useNavigate()

  useEffect(() => {
    getOrganizations({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      search,
    }).then(data => {
      setData(data)
      dispatch({
        type: Action.org,
        payload: { page, rowsPerPage, search },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage])

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
      debounce(() => {
        getOrganizations({
          offset: 0,
          limit: rowsPerPage,
          search: value,
        }).then(data => {
          setPage(0)
          setData(data)
          dispatch({
            type: Action.org,
            payload: { page: 0, rowsPerPage, search: value },
          })
        })
      }, 400)()
    },
    [rowsPerPage, dispatch]
  )

  const handleRowClick = (id: number) => navigate(routes.organization(id))

  return (
    <Page>
      <TableHeader
        title="Організації"
        onSearchChange={handleSearch}
        searchDefaultValue={search}
      />
      {data && (
        <Table
          currentPage={page}
          onChangePage={setPage}
          columns={ORG_TABLE_COLUMNS}
          rows={data.results}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={data.count}
          onRowClick={handleRowClick}
        />
      )}
    </Page>
  )
}
