import React, { useState, MouseEvent, useContext } from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LogoutIcon from '@mui/icons-material/Logout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Typography } from '@mui/material'
import { Action } from '../../types'
import { logout } from '../../api'
import { AppContext } from '../../utils'
import routes from '../../routes'
import styles from './Header.module.scss'

const navItems = [
  { title: 'організації', path: routes.organizations },
  { title: 'волонтери', path: routes.volunteers },
]

export function Header({ isLogin = false }: { isLogin?: boolean }) {
  const { dispatch, user } = useContext(AppContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = async () => {
    logout().then(() => dispatch({ type: Action.user, payload: null }))
  }

  return (
    <AppBar position="static" sx={{ mb: 2, p: 1 }}>
      <Toolbar>
        <h1 className={styles.header}>
          <span>Опис волонтерів</span>
          <span>Харкова і Харківської області</span>
        </h1>
        {isLogin && (
          <Typography
            variant="h6"
            component="h2"
            sx={{ textTransform: 'uppercase', textAlign: 'right', flexGrow: 1 }}
          >
            вхід до реєстру
          </Typography>
        )}
        {!isLogin && (
          <nav className={styles.nav}>
            {navItems.map(({ title, path }) => (
              <Link to={path} key={title}>
                <Button className={styles.menu_button}>{title}</Button>
              </Link>
            ))}
          </nav>
        )}
        {!isLogin && (
          <div>
            <Button
              aria-controls={open ? 'customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              size="small"
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
            >
              Мій профіль
            </Button>
            <Menu
              id="customized-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem>{user?.username}</MenuItem>
              <MenuItem>{`${user?.first_name} ${user?.last_name}`}</MenuItem>
              <MenuItem onClick={handleClose}>
                <Button
                  onClick={handleLogout}
                  variant="text"
                  startIcon={<LogoutIcon />}
                  fullWidth
                >
                  вихід
                </Button>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}
