import React from 'react'
import { Alert, Stack } from '@mui/material'
import styles from './Table.module.scss'

export function ArchivedBadgeCell({ text }: { text: string }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack>
        <span className={styles.cell_text}>{text}</span>
      </Stack>
      <Alert
        severity="warning"
        sx={{ alignItems: 'center', ml: 2, px: '8px', py: '2px' }}
      >
        Архівовано
      </Alert>
    </Stack>
  )
}

export function BlackListBadgeCell({ text }: { text: string }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignContent="center">
      <Stack>
        <span className={styles.cell_text}>{text}</span>
      </Stack>
      <Alert
        severity="error"
        sx={{ alignItems: 'center', ml: 2, px: '8px', py: '2px' }}
      >
        В чорному списку
      </Alert>
    </Stack>
  )
}
