import React, { useEffect, useState } from 'react'
import { Divider, Typography, Alert, useTheme } from '@mui/material'
import { Page } from '../components/Page'
import { Loader } from '../components/Loader'
import { Table } from '../components/Table'
import { getOrganizationById, getOrgVolunteers } from '../api'
import { useNavigate, useParams } from 'react-router-dom'
import { OrgDataList } from '../components/OrgDataList'
import { OrganizationDetailResponse, OrgVolunteersResponse } from '../types'
import { TABLE_ROWS_PER_PAGE, ORG_VOLUNTEERS_TABLE_COLUMNS } from '../constants'
import routes from '../routes'

export function Organization() {
  const { id } = useParams()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [orgData, setOrgData] = useState<OrganizationDetailResponse>()
  const [volunteers, setVolunteers] = useState<OrgVolunteersResponse>()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_PER_PAGE[1])

  useEffect(() => {
    getOrganizationById(Number(id)).then(data => setOrgData(data))
  }, [id])

  useEffect(() => {
    getOrgVolunteers({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      org_id: id!,
    }).then(data => setVolunteers(data))
  }, [id, page, rowsPerPage])

  const handleRowClick = (id: number) =>
    navigate(routes.volunteer(id), { state: { org: orgData?.name } })

  if (orgData && volunteers)
    return (
      <Page size="lg">
        {orgData.black_list && (
          <Alert severity="error" sx={{ mb: 2 }}>
            В чорному списку
          </Alert>
        )}
        {orgData.is_deleted && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Архівовано
          </Alert>
        )}
        <Typography
          variant="h6"
          component="h2"
          align="center"
          fontWeight={600}
          gutterBottom
        >
          {orgData.name}
        </Typography>
        <OrgDataList data={orgData} />
        <Divider sx={{ my: 4 }} variant="middle" />

        <Typography
          variant="subtitle1"
          component="h3"
          align="center"
          gutterBottom
          sx={{ color: 'white', background: palette.primary.main }}
        >
          Список волонтерів
        </Typography>
        <Table
          currentPage={page}
          onChangePage={setPage}
          columns={ORG_VOLUNTEERS_TABLE_COLUMNS}
          rows={volunteers.results}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={volunteers.count}
          onRowClick={handleRowClick}
        />
      </Page>
    )
  return <Loader />
}
