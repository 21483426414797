import React from 'react'
import { Stack, CircularProgress } from '@mui/material'

export function Loader() {
  return (
    <Stack
      sx={{ width: '100vw', height: '100vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Stack>
  )
}
