import axios from 'axios'

export const API_BASE_URL = '/api'

export default createInstance()

function createInstance() {
  const inst = axios.create({
    baseURL: API_BASE_URL,
    // Django
    // @see https://docs.djangoproject.com/en/3.2/ref/csrf/#ajax
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: { 'Accept-Language': 'uk' },
  })

  inst.interceptors.request.use(request => {
    request.url = ensureTrailingSlash(request.url ?? '/')
    return request
  })

  return inst
}

function ensureTrailingSlash(str: string): string {
  return str.replace(/([^/])$/, '$1/')
}
