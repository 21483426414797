import {
  Action,
  AppInitialState,
  ReducerActionType,
  TableSettings,
  User,
} from '../types'
import { tableSettingsDefault } from './context'

export const appInitialState = {
  user: null,
  organizations: tableSettingsDefault,
  volunteers: tableSettingsDefault,
}

export function appReducer(
  state: AppInitialState,
  action: ReducerActionType
): AppInitialState {
  switch (action.type) {
    case Action.user:
      return { ...state, user: action.payload as User }
    case Action.org:
      return { ...state, organizations: action.payload as TableSettings }
    case Action.volunteers:
      return { ...state, volunteers: action.payload as TableSettings }
    default:
      return state
  }
}
