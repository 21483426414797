import React, { FormEvent, useState, useContext } from 'react'
import { TextField, Paper, FormHelperText } from '@mui/material'
import { redirect } from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import { Login } from '@mui/icons-material'
import { AppContext } from '../../utils'
import { login } from '../../api'
import { Action } from '../../types'
import routes from '../../routes'
import styles from './LoginFrom.module.scss'

interface FormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement
  password: HTMLInputElement
}
interface FormElements extends HTMLFormElement {
  readonly elements: FormElements
}

export function LoginForm() {
  const [formError, setFormError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const { dispatch } = useContext(AppContext)

  const handleSubmit = (e: FormEvent<FormElements>) => {
    e.preventDefault()
    setLoading(true)
    const { username, password } = e.currentTarget.elements
    login({
      username: username.value,
      password: password.value,
    })
      .then(({ user }) => {
        dispatch({ type: Action.user, payload: user })
        redirect(routes.organizations)
      })
      .catch(err => {
        if (err.response.status >= 500) {
          setFormError('Сервер не відповідає')
        } else {
          setFormError("Неправильне ім'я або пароль")
        }
      })
      .finally(() => setLoading(false))
  }

  const handleFormClick = () => setFormError(null)

  return (
    <Paper elevation={3}>
      <form
        className={styles.form}
        onSubmit={handleSubmit}
        onClick={handleFormClick}
      >
        {/* <h2 className={styles.header}>вхід</h2> */}
        <StyledEngineProvider injectFirst>
          <TextField
            type="text"
            label="Ім'я користувача"
            variant="outlined"
            name="username"
            className={styles.input}
            size="small"
            required
          />
          <TextField
            type="password"
            label="Пароль"
            variant="outlined"
            name="password"
            className={styles.input}
            size="small"
            required
          />
          {formError && (
            <FormHelperText error sx={{ mb: 2, textAlign: 'center' }}>
              {formError}
            </FormHelperText>
          )}
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<Login />}
            variant="outlined"
            fullWidth
            type="submit"
          >
            Увійти
          </LoadingButton>
        </StyledEngineProvider>
      </form>
    </Paper>
  )
}
