export const TABLE_ROWS_PER_PAGE = [30, 50, 100]

export const ORG_TABLE_COLUMNS = [
  { title: 'Назва', name: 'name' },
  { title: 'Номер організації', name: 'serial_number' },
  { title: 'ПІБ керівника', name: 'full_name' },
  { title: 'Телефон керівника', name: 'head_phone_number' },
  { title: 'ЄДРПОУ', name: 'edrpou' },
]

export const VOLUNTEERS_TABLE_COLUMNS = [
  { title: 'ID', name: 'id' },
  { title: 'Прізвище', name: 'last_name' },
  { title: "Ім'я", name: 'first_name' },
  { title: 'По-батькові', name: 'middle_name' },
  { title: 'Телефон', name: 'phone_number' },
  { title: 'Номер посвідчення', name: 'serial_number' },
  { title: 'Організація', name: 'organization' },
]

export const ORG_VOLUNTEERS_TABLE_COLUMNS = [
  { title: 'Прізвище', name: 'last_name' },
  { title: "Ім'я", name: 'first_name' },
  { title: 'По-батькові', name: 'middle_name' },
  { title: 'Телефон', name: 'phone_number' },
  { title: 'Номер посвідчення', name: 'serial_number' },
]
