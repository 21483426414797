import React, { useEffect, useState } from 'react'
import { List, Stack } from '@mui/material'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import PersonIcon from '@mui/icons-material/Person'
import BusinessIcon from '@mui/icons-material/Business'
import NumbersIcon from '@mui/icons-material/Numbers'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import DescriptionIcon from '@mui/icons-material/Description'
import InfoIcon from '@mui/icons-material/Info'
import SubjectIcon from '@mui/icons-material/Subject'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import { DataListItem } from '../DataListItem'
import { Links } from './Links'
import { getAddress } from '../../helpers'
import { OrganizationDetailResponse } from '../../types'
import styles from '../VolunteerInfo/VolunteerInfo.module.scss'

interface OrgDataListProps {
  data: OrganizationDetailResponse
}

const validateLogo = (logo: string): string | null => {
  const extension = logo.split('.').pop()?.toLowerCase()
  const allowedImageTypes: { [key: string]: string } = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
  }
  if (!extension) return null
  if (!allowedImageTypes[extension]) {
    return `Неправильний формат файла. Дозволяється тільки jpg, jpeg, png.`
  }
  return null
}

export function OrgDataList({ data }: OrgDataListProps) {
  const [imageError, setImageError] = useState<string | boolean>(
    data.logo ? false : true
  )
  const handleImageLoad = () => {
    setImageError(false)
  }
  const handleImageError = () => {
    setImageError('Failed to load image.')
  }
  const validateAndLoadLogo = () => {
    if (!data.logo) return
    const validationError = validateLogo(data.logo)
    if (validationError) {
      setImageError(validationError)
      return
    }
  }
  useEffect(() => {
    validateAndLoadLogo()
  }, [data.logo])

  return (
    <Stack direction="row">
      <div>
        {imageError && <BusinessIcon className={styles.image_plug} />}
        {!imageError && (
          <img
            className={styles.image}
            onError={() => setImageError(true)}
            src={data.logo}
            alt="Логотип"
          />
        )}
      </div>
      <List>
        <DataListItem
          name="Тип організації"
          value={data.type}
          icon={<SubjectIcon color="primary" />}
        />
        <DataListItem
          name="Адреса"
          value={getAddress(data.address)}
          icon={<MapsHomeWorkIcon color="primary" />}
        />
        <DataListItem
          name="ЄДРПОУ"
          value={data.edrpou}
          icon={<TextSnippetIcon color="primary" />}
        />
        <DataListItem
          name="Голова організації"
          value={data.head_full_name}
          icon={<PersonIcon color="primary" />}
        />
        <DataListItem
          name="Телефон голови"
          value={data.head_phone_number}
          icon={<ContactPhoneIcon color="primary" />}
        />
        <DataListItem
          name="Серійний номер"
          value={data.serial_number}
          icon={<NumbersIcon color="primary" />}
        />
        <DataListItem
          name="Рід діяльності"
          value={data.activity?.map(activity => activity?.name)?.join(', ')}
          icon={<AssignmentTurnedInIcon color="primary" />}
        />
        <DataListItem
          name="Опис"
          value={data.description}
          icon={<DescriptionIcon color="primary" />}
        />
        <DataListItem
          name="Деталі"
          value={data.details}
          icon={<InfoIcon color="primary" />}
        />
        <Links urls={data.urls} />
      </List>
    </Stack>
  )
}
