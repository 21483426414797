import React from 'react'
import { ListItem } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import { Url } from '../../types'
import styles from './OrgDataList.module.scss'

interface LinksProps {
  urls: Url[]
}

export function Links({ urls }: LinksProps) {
  if (urls.length === 0) return null

  return (
    <StyledEngineProvider injectFirst>
      <ListItem className={styles.list_item}>
        <div className={styles.item_text}>
          <span className={styles.item_name}>
            {urls.length > 1 ? 'Вебсайти:' : 'Вебсайт:'}
          </span>
          <span className={styles.url_wrapper}>
            {urls.map(({ site_name, url }: Url) => (
              <a
                className={styles.link}
                key={url}
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                {site_name}
              </a>
            ))}
          </span>
        </div>
      </ListItem>
    </StyledEngineProvider>
  )
}
