import React, { ReactNode } from 'react'
import { Container, ContainerProps } from '@mui/material'
import { Header } from '../Header'

interface IPageProps {
  children: ReactNode
  size?: ContainerProps['maxWidth']
}

export function Page({ children, size = 'xl' }: IPageProps) {
  return (
    <>
      <Header />
      <Container maxWidth={size}>{children}</Container>
    </>
  )
}
