import React, { ReactNode } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { StyledEngineProvider } from '@mui/material/styles'
import styles from './DataListItem.module.scss'

interface ListItemProps {
  name: string
  value: ReactNode
  icon?: ReactNode
  emptyIcon?: boolean
}

export function DataListItem(props: ListItemProps) {
  const { name, value, icon, emptyIcon = false } = props
  const isShowIcon = icon || emptyIcon

  if (value)
    return (
      <StyledEngineProvider injectFirst>
        <ListItem className={styles.list_item}>
          {isShowIcon && <ListItemIcon sx={{ mt: 0.5 }}>{icon}</ListItemIcon>}
          <ListItemText className={styles.item_text}>
            <span className={styles.item_name}>{name}: </span>
            <span>{value}</span>
          </ListItemText>
        </ListItem>
      </StyledEngineProvider>
    )

  return null
}
