import React from 'react'
import { Stack } from '@mui/material'
import { LoginForm } from '../components/LoginForm'
import { Header } from '../components/Header'

export function Login() {
  return (
    <>
      <Header isLogin />
      <Stack
        sx={{ width: '100vw', height: '80vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <LoginForm />
      </Stack>
    </>
  )
}
