import React, { useState } from 'react'
import { Paper, Stack, Typography, TextField } from '@mui/material'

interface TableHeaderProps {
  title: string
  onSearchChange: (value: string) => void
  searchDefaultValue: string
}

export function TableHeader(props: TableHeaderProps) {
  const { title, onSearchChange, searchDefaultValue } = props
  const [searchValue, setSearchValue] = useState(searchDefaultValue)

  return (
    <Paper sx={{ mb: 2 }} variant="outlined">
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        sx={{ p: 2 }}
      >
        <Typography
          variant="h6"
          component="h2"
          sx={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
        <TextField
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value)
            onSearchChange(e.target.value)
          }}
          label="Пошук"
          variant="outlined"
          size="small"
        />
      </Stack>
    </Paper>
  )
}
